<template>
  <div class="sys-category">
    <x-table
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      :update-show-children="true"
      :load-data="handleLoadData"
      @on-expand-tree="expand"
      rowKey="id"
      ref="table"
      @add="add"
    >
    </x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="字典名称" prop="name">
          <Input v-model="form.name"></Input>
        </FormItem>
        <FormItem label="排序" prop="sort">
          <Input v-model="form.sort"></Input>
        </FormItem>
        <FormItem label="描述" prop="description">
          <Input v-model="form.description" type="textarea" :rows="3"></Input>
        </FormItem>
        <FormItem label="图片" prop="img">
          <upload-image v-model="form.img" :max="1" :clearFlag="!modal.show"></upload-image>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>
<script>
import CU from "@/common/util";
export default {
  data() {
    return {
      table: {
        columns: [
          {
            title: "字典名称",
            minWidth: 100,
            tree: true,
            key: "name",
          },
          {
            title: "描述",
            minWidth: 100,
            key: "description",
          },
          {
            title: "排序",
            width: 100,
            key: "sort",
          },
          {
            title: "操作",
            width: 300,
            render: (h, { row }) => {
              return (
                <div>
                  <a style="margin-right: 10px" onClick={() => this.add(row)}>
                    新增下级字典
                  </a>
                  <a style="margin-right: 10px" onClick={() => this.edit(row)}>
                    编辑
                  </a>
                  
                </div>
                // <Poptip
                //     confirm
                //     transfer
                //     title="确定删除吗?"
                //     on-on-ok={() => this.del(row.id)}
                //   >
                //     <a>删除</a>
                //   </Poptip>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      config: {
        filter: {
          add: {
            addBtnName: "新增顶层字典",
            ca: "sysCategoryAdd",
          },
        },
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        name: "",
        pid: "0",
        sort: "",
        description: "",
        img: "",
      },
      rules: {
        name: [{ required: true, message: "请填写字典名称" }],
      },
      open_children: new Map(),
      dataId: "",
    };
  },
  methods: {
    add(row) {
      row && (this.form.pid = row.id);
      this.modal = {
        show: true,
        title: row ? `新增【${row.name}】的下级分类` : "新增字典",
        submitLoading: false,
      };
    },
    getList() {
      this.table.loading = true;
      this.$post(this.$api.SYS_CATEGORY.LIST_TREE)
        .then((res) => {
          this.open_children = new Map();
          this.setOpenChildren(res.list);
          this.table.data = res.list.map((item) => {
            let obj = {
              ...item,
              children: [],
              _loading: false,
              _showChildren: false,
            };
            return obj;
          });
          if (this.dataId) {
            let obj = this.expandData(res.list);
            let index = this.table.data.findIndex((item) => item.id == obj.id);
            this.table.data.splice(index, 1, obj);
          }
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    expandData(n) {
      let result = {};
      n.forEach((item) => {
        if (item.id == this.dataId && item.pid != "0") {
          result = { ...item, children: item.childList, _showChildren: true };
          return;
        } else if (item.id == this.dataId && item.pid == "0") {
          result = { ...item, children: item.childList, _showChildren: false };
          return;
        }
        if (item.childList && item.childList.length > 0) {
          let subResult = this.expandData(item.childList);
          if (Object.keys(subResult).length > 0) {
            let index = item.childList.findIndex((el) => el.id == subResult.id);
            item.childList.splice(index, 1, subResult);
            result = { ...item, children: item.childList, _showChildren: true };
          }
        }
      });
      return result;
    },
    setOpenChildren(data) {
      data.forEach((item) => {
        if (item.pid != "0") {
          item.children = [];
          if (item.childList && item.childList.length > 0) {
            item._loading = false;
            item._showChildren = false;
          }
          if (!this.open_children.has(item.pid)) {
            this.open_children.set(item.pid, [item]);
          } else {
            let n = this.open_children.get(item.pid);
            n.push(item);
            this.open_children.set(item.pid, n);
          }
        }
        if (item.childList && item.childList.length > 0) {
          this.setOpenChildren(item.childList);
        }
      });
    },
    handleLoadData(item, callback) {
      let data;
      if (this.open_children.has(item.id)) {
        data = this.open_children.get(item.id);
      }
      callback(data);
      // this.$refs.table.handleLoadData(item, callback);
    },
    expand(rowKey, status) {
      if (!status) {
        this.delCurrentChildren(rowKey, this.table.data);
      }
    },
    delCurrentChildren(id, data) {
      data.forEach((item) => {
        if (item.id == id) {
          item.children = [];
          item._loading = false;
          return;
        }
        if (item.children && item.children.length > 0) {
          this.delCurrentChildren(id, item.children);
        }
      });
    },
    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      row.img && (this.form.img = row.servicePath + row.img)
      this.dataId = row.id;
      this.form.id = row.id;
      this.modal = {
        show: true,
        title: "编辑字典",
        submitLoading: false,
      };
    },
    del(id) {
      this.$post(this.$api.SYS_CATEGORY.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        name: "",
        pid: "0",
        sort: "",
        description: "",
        img: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        this.form.img &&
          (params.img = "image" + this.form.img.split("image")[1]);
        this.modal.submitLoading = true;
        this.$post(
          params.id ? this.$api.SYS_CATEGORY.EDIT : this.$api.SYS_CATEGORY.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>